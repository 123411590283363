import { template as template_4fddcc2d07bb4ddcb3de75f99414bcec } from "@ember/template-compiler";
const FKText = template_4fddcc2d07bb4ddcb3de75f99414bcec(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
