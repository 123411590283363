import { template as template_6f98866c834240efbd67c9239f5f4e0b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_6f98866c834240efbd67c9239f5f4e0b(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
