import { template as template_2bf1d1310fbf4ab389997a101b11b626 } from "@ember/template-compiler";
const FKLabel = template_2bf1d1310fbf4ab389997a101b11b626(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
